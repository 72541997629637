import { render, staticRenderFns } from "./comp_ShopFilters.vue?vue&type=template&id=c31895b6&scoped=true&"
import script from "./comp_ShopFilters.vue?vue&type=script&lang=js&"
export * from "./comp_ShopFilters.vue?vue&type=script&lang=js&"
import style0 from "./comp_ShopFilters.vue?vue&type=style&index=0&id=c31895b6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c31895b6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBreadcrumbs,VChip,VIcon,VSkeletonLoader})

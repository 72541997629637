<script>
import { get } from 'vuex-pathify'
import _ from 'lodash'
const SHOP_ALL_KEYWORD = process.env.VUE_APP_SHOP_ALL_KEYWORD
const _cachedResults = {}

export default {
	lang: 'shop',
	metaInfo() {
		let title = this.loading ? '...' : this.shop?.context?.title?.replace('/', '>') || 'Shop'
		let description = this.loading ? null : this.shop?.context?.description || title
		return { title, meta: [{ vmid: 'd', name: 'description', content: description }] }
	},
	data() {
		return {
			loading: false,
			bottomLoading: false,
			showFiltersDrawer: false,
			shop: {
				products: [],
				pagination: {
					page: 0,
					lastPage: 0,
					itemsLength: 0,
				},
				possibleFilters: {},
				removalChips: [],
				breadcrumbs: [],
				meta: {},
				context: {},
			},
			currentRouteData: null,
			showNoResultsProducts: false,
			bottomReached: false,
			renderBottonObserver: false,
		}
	},
	watch: {
		routeParams: {
			handler() {
				this.loadData()
			},
			deep: true,
		},
		routeSearch() {
			this.loadData()
		},
		bottomReached(value) {
			if (value && this.shop.pagination.page) {
				this.bottomReached = false
				this.loadData(this.shop.pagination.page + 1)
			}
		},
	},
	computed: {
		routeParams: get('route@params'),
		routeSearch: get('route@query.search'),
		collectionImage() {
			return this.$vuetify.breakpoint.smAndDown
				? this.shop.context.imageMobileUrl
				: this.shop.context.imageUrl
		},
		noResultsProducts() {
			return []
		},
	},
	methods: {
		routeToData() {
			let { filters } = this.routeParams
			if (typeof filters == 'string') {
				filters = filters.split('/').filter((str) => !!str.trim())
			}
			if (!filters.length) return false

			let data = { filters: [] }
			let subFilters = [
				{
					re: /^brand-(.+)$/,
					onMatch: (m) => {
						data.brand = m[1]
					},
				},
				{
					re: /^from-([0-9]+)(-to-([0-9]+))?$/,
					onMatch: (m) => {
						data.fromPrice = m[1]
						data.toPrice = m[3]
					},
				},
				{
					re: /^sort-by-(.+)$/,
					onMatch: (m) => {
						data.sortBy = m[1]
					},
				},
				{
					re: /^(.+)--(.+)$/,
					repeat: true,
					onMatch: (m) => {
						data.attrs = data.attrs || {}
						data.attrs[m[1]] = m[2]
					},
				},
			]

			let subFilterIndex = -1
			for (let filter of filters) {
				filter = String(filter)

				let subFilter = subFilters.find((subFilter, i) => {
					if (i < subFilterIndex) return false
					let m = filter.match(subFilter.re)
					if (!m) return false
					subFilter.onMatch(m)
					subFilterIndex = subFilter.repeat ? i : i + 1
					return true
				})

				if (subFilterIndex == -1) {
					data.filters.push(filter)
				} else if (!subFilter || !data.filters.length) {
					return false
				}
			}

			data.search = this.routeSearch
			return data
		},
		dataToRoute(data = {}) {
			let filters = [...(data.filters || [SHOP_ALL_KEYWORD])]

			if (data.brand) {
				filters.push(`brand-${data.brand}`)
			}
			let { fromPrice, toPrice } = data
			if (isNaN(fromPrice) || fromPrice < 0) fromPrice = 0
			if (isNaN(toPrice) || toPrice <= 0 || (fromPrice && toPrice < fromPrice)) {
				toPrice = null
			}
			if (fromPrice || toPrice) {
				let filterStrs = []
				filterStrs.push(`from-${parseInt(fromPrice)}`)
				if (toPrice) filterStrs.push(`to-${parseInt(toPrice)}`)
				filters.push(filterStrs.join('-'))
			}
			if (data.sortBy) {
				filters.push(`sort-by-${data.sortBy}`)
			}
			if (data.attrs) {
				Object.entries(data.attrs)
					.sort((a, b) => (a[0] > b[0] ? 1 : -1))
					.forEach((attr) => {
						filters.push(`${attr[0]}--${attr[1]}`)
					})
			}

			let route = { name: 'shop', params: { filters } }
			let { search } = data
			if (search) route.query = { search }
			return route
		},
		getListId() {
			return window.location.pathname
		},
		async loadData(page = 1) {
			this.showFiltersDrawer = false
			let postData = this.routeToData()
			let viewProductsList = null
			let listInitialIndex = 0
			if (postData === false) {
				this.showNoResultsProducts = true
			} else {
				let cachedShop = page == 1 ? this.getCache() : null
				if (cachedShop === false) {
					this.showNoResultsProducts = true
				} else if (cachedShop) {
					this.$assign(this.shop, cachedShop)
					viewProductsList = this.shop.products
				} else {
					await this.$shopApi.post({
						url: '/catalog/shop',
						data: { ...postData, page },
						loading: (v) => {
							if (page == 1) this.loading = v
							else this.bottomLoading = v
						},
						onSuccess: ({ data }) => {
							if (data.shop === false) {
								this.showNoResultsProducts = true
								this.setCache(false)
							} else {
								let currentProducts = this.shop.products || []
								viewProductsList = data.shop.products
								if (page == 1) {
									this.setCache(data.shop)
								} else {
									listInitialIndex = currentProducts.length
									data.shop.products = currentProducts.concat(data.shop.products)
								}
								this.$assign(this.shop, data.shop)
								this.showNoResultsProducts = !this.shop.products?.length
							}
						},
					})
				}
			}

			if (page == 1) {
				this.$eventer.trigger('page:view')
				this.$eventer.trigger('shop-list:loaded')
				this.$prerenderReady()
			}
			if (viewProductsList?.length) {
				this.triggerListView(viewProductsList, listInitialIndex)
			}
			let pagination = this.shop?.pagination
			if (pagination?.page && pagination.page < pagination.lastPage) {
				setTimeout(() => {
					this.renderBottonObserver = true
				}, 500)
			} else {
				this.renderBottonObserver = false
			}
		},
		triggerListView(products, initialIndex) {
			if (!products?.length) return
			let listId = this.getListId()
			products.forEach((product, i) => (product.index = initialIndex + i))
			this.$eventer.trigger('product-list:view', { products, listId })
		},
		getCacheKey() {
			let { pathname, search } = window.location
			return `${pathname}${search}`
		},
		setCache(shop) {
			let key = this.getCacheKey()
			_cachedResults[key] = { shop: _.cloneDeep(shop), at: Date.now() }
		},
		getCache() {
			let key = this.getCacheKey()
			if (!_cachedResults[key]) return null
			let { shop, at } = _cachedResults[key]
			//3 minutes
			if (Date.now() - at > 60000 * 3) {
				delete _cachedResults[key]
				return null
			}
			return shop
		},
		toggleShopAllKeyword(filters, b) {
			if (b && !filters.length) {
				filters.push(SHOP_ALL_KEYWORD)
			} else if (!b && filters[0] == SHOP_ALL_KEYWORD) {
				filters.splice(0, 1)
			}
		},
		getFilterRoute(type, value, added) {
			let data = this.routeToData()
			let { filters } = data

			if (type == 'brand') {
				data.brand = added ? value : null
			} else if (type == 'collection') {
				if (added) {
					this.toggleShopAllKeyword(filters, false)
					filters.unshift(value)
				} else {
					filters.shift()
					this.toggleShopAllKeyword(filters, true)
				}
			} else if (type == 'category') {
				if (added) {
					this.toggleShopAllKeyword(filters, false)
					filters.push(value)
				} else {
					filters.pop()
					this.toggleShopAllKeyword(filters, true)
				}
			} else if (type == 'sortBy') {
				data.sortBy = added ? value : null
			} else if (type == 'priceRange') {
				if (added) {
					let [fromPrice, toPrice] = value
					data.fromPrice = fromPrice
					data.toPrice = toPrice
				} else {
					data.fromPrice = data.toPrice = null
				}
			} else if (type == 'attr') {
				data.attrs = data.attrs || {}
				if (added) data.attrs[value[0]] = value[1]
				else delete data.attrs[value[0]]
			} else if (type == 'search') {
				if (!added) data.search = null
			}
			return this.dataToRoute(data)
		},
		getBreadcrumbRoute(urlNames) {
			let data = this.routeToData()
			let { filters } = data
			let isCollectionFiltered = !!this.shop.removalChips.find((rc) => rc.type == 'collection')
			let index = isCollectionFiltered ? 1 : 0
			filters.splice(index, filters.length, ...urlNames)
			this.toggleShopAllKeyword(filters, true)
			return this.dataToRoute(data)
		},
		pushRoute(data) {
			let route = this.dataToRoute(data)
			this.$router.push(route)
		},
		toggleDrawer() {
			this.showFiltersDrawer = !this.showFiltersDrawer
		},
	},
	provide() {
		return {
			getFilterRoute: (...args) => this.getFilterRoute(...args),
			getBreadcrumbRoute: (...args) => this.getBreadcrumbRoute(...args),
		}
	},
	created() {
		this.loadData()
	},
}
</script>

<template>
	<div
		v-if="loading"
		class="d-flex justify-center py-16 mt-8"
		:style="{ 'min-height': 'calc(100vh - 100px)' }"
	>
		<v-progress-circular :size="70" :width="7" color="primary" indeterminate />
	</div>
	<div v-else-if="shop.products.length">
		<div class="pb-6" v-if="collectionImage">
			<Media width="100%" :src="collectionImage" />
		</div>
		<Container class="pt-6">
			<v-row>
				<v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
					<ShopFilters
						class="px-4 px-sm-0"
						:loading="loading"
						:title="shop.context.title"
						:breadcrumbs="shop.breadcrumbs"
						:total-results="shop.pagination.itemsLength"
						:removal-chips="shop.removalChips"
					/>
					<v-bottom-navigation dark fixed>
						<Button @click="toggleDrawer" block color="primary" large>
							<v-icon>mdi-filter</v-icon> {{ 'FILTRAR / ORDENAR' | lang }}
						</Button>
					</v-bottom-navigation>
				</v-col>
				<v-col cols="12" sm="4" md="3" v-else>
					<ShopFilters
						class="pr-2"
						:loading="loading"
						:title="shop.context.title"
						:breadcrumbs="shop.breadcrumbs"
						:total-results="shop.pagination.itemsLength"
						:category-items="shop.possibleFilters.category"
						:brand-items="shop.possibleFilters.brand"
						:collection-items="shop.possibleFilters.collection"
						:attrs-groups="shop.possibleFilters.attrsGroups"
						:sort-by-items="shop.possibleFilters.sortBy"
						:removal-chips="shop.removalChips"
					/>
				</v-col>
				<v-col cols="12" md="9">
					<ProductsLayout
						:loading="loading"
						:products="shop.products"
						:list-id="getListId()"
						:trigger-list-view="false"
					/>
					<div class="text-center py-8">
						<div class="pb-3 font-0" v-if="bottomLoading">Cargando mas productos...</div>
						<v-progress-linear color="primary" indeterminate v-if="bottomLoading" />
					</div>
					<v-lazy v-model="bottomReached" v-if="renderBottonObserver" :key="shop.pagination.page" />
				</v-col>
				<v-navigation-drawer
					class="pa-4 pr-0 pl-0"
					:value="showFiltersDrawer"
					stateless
					fixed
					width="600"
					style="z-index: 11"
				>
					<v-list-item class="px-2">
						<div class="pl-4">{{ 'FILTRAR / ORDENAR' | lang }}</div>
						<v-spacer />
						<Button :ripple="false" plain text @click="toggleDrawer">
							<v-icon x-large>mdi-close-circle</v-icon>
						</Button>
					</v-list-item>
					<v-divider class="mb-4"></v-divider>
					<div class="pl-5">
						<ShopFilters
							:loading="loading"
							:title="shop.context.title"
							:breadcrumbs="shop.breadcrumbs"
							:total-results="shop.pagination.itemsLength"
							:category-items="shop.possibleFilters.category"
							:brand-items="shop.possibleFilters.brand"
							:collection-items="shop.possibleFilters.collection"
							:attrs-groups="shop.possibleFilters.attrsGroups"
							:sort-by-items="shop.possibleFilters.sortBy"
							:removal-chips="shop.removalChips"
						/>
					</div>
				</v-navigation-drawer>
			</v-row>
		</Container>
	</div>
	<div
		v-else-if="showNoResultsProducts"
		class="d-flex justify-center align-center pt-8 pb-16 flex-column text-center"
	>
		<v-icon x-large color="#ccc darken-1"> mdi-thumb-down</v-icon>
		<h1>Ups! Lo sentimos...</h1>
		<div class="mb-8 font-2 px-4">No encontramos productos con stock disponibles para tu búsqueda.</div>
		<template v-if="noResultsProducts.length">
			<h2>La mejores ofertas para vos</h2>
			<Container width="1200px" max-width="95%">
				<ProductsLayout :products="noResultsProducts" list-id="shop-no-results" :cards-per-line="4" />
			</Container>
		</template>
		<router-link class="font-3 mt-8" :to="$shop.getShopRoute()">Volver al inicio</router-link>
	</div>
</template>
